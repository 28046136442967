<template>
    <div>
        <div class="page-header justify-content-between">
            <lable class="page-title">Create New Schedule</lable> 
            <div class="create_actions">
              <button>CANCEL</button>
              <button>SAVE</button>
          </div>
        </div>
        <div class="create_sec">
          <div class="create_cnt">
              <div class="create_cnt_list">
                <h6>Event Details -</h6>
                <ul>
                  <li>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Subject *</label>
                      <input type="text" class="form_control subject">
                    </div>
                  <li>
                   <div class="d-flex align-items-center">
                      <label class="form_label">Date *</label>
                      <div class="date_range ">
                        <dateRange></dateRange>                                 
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Time *</label>
                      <div class="time_block">
                        <multiselect
                          v-model="timeSelected" 
                          :show-labels="false" 
                          :options="timeOptions">
                        </multiselect>
                        <multiselect
                            v-model="timeSelected" 
                            :show-labels="false" 
                            :options="timeOptions">
                        </multiselect>
                      </div>
                    </div>
                  </li>
                  <li class="recurrence">
                    <div class="d-flex align-items-center">
                      <label class="form_label">Recurrence</label>
                      <multiselect
                          @input = "recurrenceChanged"
                          v-model="RecurrenceSelected" 
                          :show-labels="false" 
                          :options="RecurrenceOptions">
                      </multiselect> 
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Category *</label>
                      <multiselect
                          v-model="categorySelected" 
                          :show-labels="false" 
                          :options="categoryOptions">
                      </multiselect>
                    </div>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Visit Type *</label>
                      <multiselect
                          v-model="visitTypeSelected" 
                          :show-labels="false" 
                          :options="visitTypeOptions">
                      </multiselect>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Meeting *</label>
                      <multiselect
                          v-model="meetingSelected" 
                          :show-labels="false" 
                          :options="meetingOptions">
                      </multiselect>
                    </div>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Priority *</label>
                      <multiselect
                          v-model="prioritySelected" 
                          :show-labels="false" 
                          :options="priorityOptions">
                      </multiselect>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex">
                      <label class="form_label">Description</label>
                      <textarea class="form_control text-area" rows="5" placeholder="Enter event details"></textarea>
                    </div> 
                  </li>
                  <li>
                    <div class="d-flex">
                      <label class="form_label">Add Members*</label>
                      <div class="people_sec">
                        <input type="text" class="people_search" placeholder="Enter Name" />
                        <div class="people_list">
                          <h5>Add Team Members</h5>
                          <ul>
                            <li>
                              <figure class="dp">
                                <span><img src="@/assets/images/pooja.svg"></span>
                                <figcaption>Pooja H</figcaption>
                              </figure>
                              <em><img src="@/assets/images/plus.svg"></em>
                            </li>
                            <li>
                              <figure class="dp">
                                <span><img src="@/assets/images/pooja.svg"></span>
                                <figcaption>Purnima Ramesh</figcaption>
                              </figure>
                              <em><img src="@/assets/images/plus.svg"></em>
                            </li>
                            <li>
                              <figure class="dp">
                                <span><img src="@/assets/images/pooja.svg"></span>
                                <figcaption>Piyush Shah</figcaption>
                              </figure>
                              <em><img src="@/assets/images/plus.svg"></em>
                            </li>
                            <li>
                              <figure class="dp">
                                <span><img src="@/assets/images/pooja.svg"></span>
                                <figcaption>Prabhas Rao</figcaption>
                              </figure>
                              <em><img src="@/assets/images/plus.svg"></em>
                            </li>
                          </ul>
                        </div>  
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
          </div>
          <div class="create_cnt">
              <div class="create_cnt_list">
                <h6>Organiser Details -</h6>
                <ul>
                  <li>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Name *</label>
                      <input type="text" class="form_control">
                    </div>
                  <li>
                   <div class="d-flex align-items-center">
                      <label class="form_label">Organisation</label>
                      <input type="text" class="form_control">
                    </div>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Designation</label>
                      <input type="text" class="form_control">
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Mobile # *</label>
                      <input type="text" class="form_control">
                    </div>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Alternative *</label>
                      <input type="text" class="form_control">
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Email ID</label>
                      <input type="email" class="form_control">
                    </div> 
                  </li> 
                </ul>
              </div>
              <div class="create_cnt_list">
                <h6>Coordinator details -</h6>
                <ul>
                  <li>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Name *</label>
                      <input type="text" class="form_control">
                    </div>
                  <li>
                   <div class="d-flex align-items-center">
                      <label class="form_label">Organisation</label>
                      <input type="text" class="form_control">
                    </div>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Designation</label>
                      <input type="text" class="form_control">
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Mobile # *</label>
                      <input type="text" class="form_control">
                    </div>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Alternative *</label>
                      <input type="text" class="form_control">
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Email ID</label>
                      <input type="email" class="form_control">
                    </div> 
                  </li> 
                </ul>
              </div>
          </div>
          <div class="create_cnt">
              <div class="create_cnt_list location_list">
                <h6>Location Details -</h6>
                <ul>
                   <li>
                    <div class="d-flex">
                      <label class="form_label">Meeting Location *</label>
                      <textarea class="form_control text-area" rows="5" placeholder="Enter details"></textarea>
                    </div> 
                    </li>
                    <li>
                      <div class="d-flex">
                        <label class="form_label">Accomodation Location</label>
                        <textarea class="form_control text-area" rows="5" placeholder="Enter details"></textarea>
                      </div> 
                    </li>
                </ul>
              </div>
          </div>
          <div class="create_cnt">
              <div class="create_cnt_list">
                <h6>My Notes -</h6>
                <ul>
                   <li>
                    <div class="d-flex">
                      <label class="form_label">Description</label>
                      <textarea class="form_control text-area" rows="5" placeholder="Enter event details"></textarea>
                    </div> 
                  </li>
                  <li>
                    <div class="d-flex">
                      <!-- <label class="form_label">Add attachments</label> -->
                      <div class="upload-btn-wrapper">
                        <button class="upload_btn">Add attachments</button>
                          <input type="file" name="myfile" />
                      </div>
                    </div>
                  </li> 
                </ul>
              </div>
          </div>
          
        </div>
        <div class="create_actions">
          <button>CANCEL</button>
          <button>SAVE</button>
        </div>
        <b-modal ref="my-modal" id="Recurrence-modal"  centered hide-header hide-footer size="500" >
            <!-- <span class="close"><span aria-hidden="true">&times;</span></span> -->
            <div class="custom_recurrence_sec">
                <h4>Custom Recurrence</h4>
                <div class="d-flex align-items-center">
                  <label class="form-label">Repeat every </label>
                  <multiselect class="repeat_count"
                      v-model="countSelected" 
                      :show-labels="false" 
                      :options="countOptions">
                  </multiselect>
                  <multiselect class="repeat_days"
                      v-model="daysSelected" 
                      :show-labels="false" 
                      :options="daysOptions">
                  </multiselect>
                </div>
                <div>
                    <label class="form-label">Repeat on</label>
                    <div class="repeat_days">
                      <ul>
                        <li v-on:click="isActive='mon'" v-bind:class="{ active: isActive=='mon' }"><a>M</a></li>
                        <li v-on:click="isActive='tue'" v-bind:class="{ active: isActive=='tue' }"><a>T</a></li>
                        <li v-on:click="isActive='wed'" v-bind:class="{ active: isActive=='wed' }"><a>W</a></li>
                        <li v-on:click="isActive='thu'" v-bind:class="{ active: isActive=='thu' }"><a>T</a></li>
                        <li v-on:click="isActive='fri'" v-bind:class="{ active: isActive=='fri' }"><a>F</a></li>
                        <li v-on:click="isActive='sat'" v-bind:class="{ active: isActive=='sat' }"><a>S</a></li>
                        <li v-on:click="isActive='sun'" v-bind:class="{ active: isActive=='sun' }"><a>S</a></li>
                      </ul>
                    </div>
                </div>
                <div>
                    <label class="form-label">Ends</label>
                    <div class="ends_on">
                      <ul>
                        <li>
                            <b-form-checkbox>Never</b-form-checkbox>

                        </li>
                        <li>
                          <b-form-checkbox>On</b-form-checkbox>
                          <input type="text" class="form_control" value="Nov 23, 2020">
                        </li>
                        <li>
                          <b-form-checkbox>After</b-form-checkbox>
                          <multiselect  
                            v-model="occurencesSelected" 
                            :show-labels="false" 
                            :options="occurencesOptions">
                        </multiselect>
                        </li>
                      </ul>
                    </div>
                    <div class="create_actions">
                      <button  @click="hideModal">CANCEL</button>
                      <button  @click="hideModal">SAVE</button>
                    </div>
                </div>

            </div>
        </b-modal>

         
    </div>
</template>

<script> 
import Multiselect from "vue-multiselect-inv";
import dateRange from '@/components/dateRange.vue'
//import { Modal } from '@amcharts/amcharts4/core';
  export default {
     components: {
        Multiselect, 
        dateRange
    },
    data() {
      return { 
        isActive: 'mon',
        visitTypeSelected:'Online',
        visitTypeOptions: ['Online ', 'Offline'], 
        prioritySelected:'High',
        priorityOptions: ['High ', 'Low', 'Medium'], 
        RecurrenceSelected:'Do not repeat',
        RecurrenceOptions: ['Do not repeat ', 'Daily', 'Weekly on Tuesday', 'Monthly on the fourth Tuesday' , 'Annually on November 23', 'Every weeday (Monday to Friday)', 'Custom...'], 
        timeSelected:'2.30 pm',
        timeOptions: ['2.30 pm ', '3.30 pm', '4.30 pm'], 
        meetingSelected: 'Internal meeting',   
        meetingOptions: ['Internal meeting ', 'External meeting',], 
        categorySelected: 'My Visit',   
        categoryOptions: ['My Visit ', 'Client Visit'], 
        countSelected: '1',   
        countOptions: ['1', '2', '3'], 
        daysSelected: 'Days',   
        daysOptions: ['Days', 'Weeks', 'Months', 'Years'], 
        occurencesSelected: '13      Occurences',   
        occurencesOptions: ['15      Occurences', '20      Occurences',], 


        
      }
    },
    methods: {
      recurrenceChanged(item) {
        if(item=='Custom...'){
          //v-b-modal.Recurrence-modal
          this.$bvModal.show('Recurrence-modal')
        }
        else {
            this.$bvModal.hide('Recurrence-modal')
        }
      },
      showModal() {
        this.$refs['my-modal'].show() 
      },
      hideModal() {
        this.$refs['my-modal'].hide() 
      },
      toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs['my-modal'].toggle('#toggle-btn') 
      },
    }
 
  }
</script>