<template>
   <vc-date-picker v-model="range" is-range>
        <template v-slot="{ inputValue, inputEvents }">
             
            <input
                :value="inputValue.start?inputValue.start +' - '+ inputValue.end:''"
                v-on="inputEvents.start"  
                placeholder="All"
                class="date"
            />                                        
            <!-- <input
                :value="inputValue.end"
                v-on="inputEvents.end"
                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
            /> -->
             
        </template>
    </vc-date-picker>
</template>

<script> 
export default {
 
  data() {
      return {
        range: {
            start: new Date('start'),
            end: new Date('end'),
        },
      }
  }
}
</script>

